<template>
  <div>
    <!-- header card -->
    <b-card>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
            :label="$t('apps.transactions.purchase.singular.supplier')"
            label-for="supplier"
            >
              <validation-provider
                #default="{ errors }"
                name="supplier"
                rules="required"
              >
                <v-select
                  inputId="supplier"
                  ref="firstFocusSelectInput"
                  v-model="form.contactId"
                  :options="LOV.contacts"
                  :reduce="field => field.id"
                  label="label"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  @option:selected="handleContact"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.singular.supplier') : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Transaction No Field -->
          <b-col cols="12" md="4">
            <b-form-group
            :label="$t('apps.transactions.purchase.invoice.singular.invoiceNo')"
            label-for="supplier"
            >
              <b-form-input
                id="invoice-no"
                size="sm"
                v-model="form.invoiceNo"
                :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.invoice.singular.invoiceNo') : null"
                :disabled="actions.isPreview"
              />
            </b-form-group>
          </b-col>

          <!-- PIC Office Field -->
         <b-col cols="12" md="4">
            <b-form-group
            :label="$t('apps.transactions.purchase.request.singular.Office')"
            label-for="requestor"
            >
              <validation-provider
                #default="{ errors }"
                name="requestor"
                rules="required"
              >
                <v-select
                  inputId="requestor"
                  ref="firstFocusSelectInput"
                  v-model="form.officeId"
                  :options="LOV.officeId"
                  :reduce="field => field.id"
                  label="label"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.request.singular.Office') : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Date Field -->
          <b-col cols="12" md="2">
            <b-form-group
            :label="$t('apps.transactions.sales.invoice.singular.invoiceDate')"
            label-for="invoice-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Invoice Date"
                rules="required"
              >
                <flat-pickr
                  id="invoice-date"
                  :config="dateFormat"
                  v-model="form.date"
                  @input="changeInvDate($event)"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="form-control form-control-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.sales.invoice.singular.invoiceDate') : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group
            :label="$t('apps.transactions.purchase.invoice.singular.dueDate')"
            label-for="due-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
              >
                <flat-pickr
                  id="due-date"
                  :config="dateFormat"
                  v-model="form.dueDate"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="form-control form-control-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.invoice.singular.dueDate') : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="form.contactId" >
            <b-form-group
            :label="$t('apps.transactions.purchase.invoice.singular.invoiceType')"
            label-for="invoice-type"
            >
              <!-- <validation-provider
                #default="{ errors }"
                name="invoice-type"
                rules="required"
              > -->
                <v-select
                  inputId="invoice-type"
                  ref="firstFocusSelectInput"
                  v-model="invoiceType"
                  :options="LOV.invoiceTypes"
                  :reduce="field => field.value"
                  label="label"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.invoice.singular.invoiceType') : null"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>
          <!-- PO Field -->
          <b-col cols="12" md="4" v-if="invoiceType === 'WITH_PO'">
            <b-form-group
              :label="$t('apps.transactions.purchase.order.singular.noOrder')"
              label-for="orders">
              <v-select
                v-model="orders"
                inputId="orders"
                label="code"
                :options="LOV.orders"
                :reduce="field => field.id"
                :searchable="!actions.isPreview"
                :selectable="option => !actions.isPreview"
                @option:selected="setPOItems"
                @option:deselected="removePOItems"
                class="select-size-sm"
                :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.order.singular.noOrder') : null"
              />
            </b-form-group>
          </b-col>
          <!-- Tag Field -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.transactionsCashbank.singular.tags')"
              label-for="tags">
              <v-select
                v-model="form.tags"
                inputId="tags"
                label="name"
                multiple
                :options="LOV.tags"
                :reduce="field => field.id"
                :searchable="!actions.isPreview"
                :selectable="option => !actions.isPreview"
                class="select-size-sm"
                :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.singular.tags') : null"
              />
            </b-form-group>
          </b-col>
        </b-row>
    </b-card>
    <div v-if="loading">
          <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle" />
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
    </div>
    <b-card>
      <b-row >
        <b-col
          cols="12"
        >
          <validation-observer ref="formRef">
            <b-form @submit.prevent>

              <b-table
                id="refFormTable"
                ref="refFormTable"
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="form.details"
                primary-key="id"
                show-empty
                class="position-relative table-sm"
              >
                <!-- Custom Header Column -->
                <template #head(actions)>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="mx-auto"
                  />
                </template>
                <!-- Column: Product -->
                <template #cell(product)="{ index }" colspan="2">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="product"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        v-model="form.details[index].itemId"
                        :options="LOV.products"
                        :reduce="field => field.id"
                        label="name"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        :clearable="false"
                        @option:selected="setAmountAndUom(index)"
                        class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        v-model="form.details[index].remark"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        :disabled="actions.isPreview"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Unit -->
                <template #cell(unit)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="unit"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        v-model="form.details[index].uomId"
                        :options="LOV.uoms"
                        :reduce="field => field.id"
                        label="uom"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        :clearable="false"
                        class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Qty -->
                <template #cell(qty)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="quantity"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        class="text-right"
                        type="number"
                        min="1"
                        v-model.number="form.details[index].quantity"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        :disabled="actions.isPreview"
                        @blur="updateItemAmount(index)"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Price -->
                <template #cell(price)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="price"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].amount"
                        :state="errors.length > 0 ? false:null"
                        class="form-control text-right form-control-sm"
                        :options="numeric"
                        :disabled="actions.isPreview"
                        :placeholder="actions.isPreview ? null : '100,000'"
                        @blur="updateItemAmount(index)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Discount Amount -->
                <template #cell(discount)="{ index }">
                  <b-form-group>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="amount"
                      rules="required"
                      :vid="`detail-${index}`"
                    > -->
                      <b-form-input
                        class="text-right"
                        type="number"
                        min="0"
                        v-model.number="form.details[index].discPercent"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        :disabled="actions.isPreview"
                        @blur="updateItemAmount(index)"
                        size="sm"
                      />
                      <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </template>
                <!-- Column: Tax Amount -->
                <template #cell(tax)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="tax"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        v-model="form.details[index].taxId"
                        :options="LOV.taxes"
                        :reduce="field => field.id"
                        label="name"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        :clearable="false"
                        @option:selected="updateItemAmountTax(index,LOV.taxes,form.details[index].taxId)"
                        class="select-size-sm"
                      />
                      <!-- tax  -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Total Amount -->
                <template #cell(total)="{ index }">
                  <span class="font-weight-bolder d-block text-right text-nowrap align-top form-control border-0 pr-0">
                    {{ formatCurrency(form.details[index].baseAmount) }}
                  </span>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="{ index }">
                  <b-button
                    variant="flat-danger"
                    class="btn-icon"
                    :disabled="form.details.length <= 2"
                    @click="removeRow(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </template>
                <!-- Costum Footer -->
                <template v-slot:custom-foot="{}">
                  <b-tr v-if="invoiceType === 'WITHOUT_PO'">
                    <b-td colspan="8" class="p-0">
                      <b-button
                        v-if="!actions.isPreview"
                        variant="outline-primary"
                        block
                        @click="addRow"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                        {{ $t('globalActions.addMore') }}
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="5">&nbsp;</b-td>
                    <b-td class="pl-5 py-1">
                      <div class="h5 mb-0 font-weight-bolder">{{ $t('globalSingular.subTotal') }}</div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="h5 mb-0 font-weight-bolder">{{ formatCurrency(sumOfAmount) }}</div>
                    </b-td>
                    <b-td>&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="5" class="border-0">&nbsp;</b-td>
                    <b-td class="border-0 pb-1 pl-5">
                      <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.discount') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline">
                      <div class="h6 mb-0 font-weight-bolder text-danger"> -{{ formatCurrency( form.sumOfDiscAmount + form.sumOfDiscGlobalAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="5" class="border-0">&nbsp;</b-td>
                    <b-td class="border-0 pb-1 pl-5">
                      <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.taxBase') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline">
                      <div class="h6 mb-0 font-weight-bolder">{{ formatCurrency( form.sumOfTaxBaseAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="5" class="border-0">&nbsp;</b-td>
                    <b-td class="border-0 pb-1 pl-5">
                      <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.tax') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline">
                      <div class="h6 mb-0 font-weight-bolder">{{ formatCurrency( form.sumOfTaxAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="5" class="border-0">&nbsp;</b-td>
                    <b-td colspan="2" class="border-0 pl-5 py-0">
                      <hr>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="5" class="border-0">
                      &nbsp;
                    </b-td>
                    <b-td class="border-0 align-baseline pl-5">
                      <div class="h4 mb-0 font-weight-bolder">{{ $t('globalSingular.total') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline pl-4">
                      <div class="h4 mb-0 font-weight-bolder">{{ formatCurrency( form.sumOfBillAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                </template>
              </b-table>

              <b-row class="my-1">
                <!-- Description Field -->
                <b-col cols="12" md="12">
                  <b-form-group
                        :label="$t('apps.transactionsCashbank.singular.notes')"
                        label-for="notes">
                    <b-form-textarea
                      id="notes"
                      size="sm"
                      v-model="form.remark"
                      rows="1"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="5" md="6">
                   <b-form-group
                     :label="$t('apps.transactionsCashbank.singular.image')"
                     label-for="images">
                     <b-form-file
                       multiple
                       :file-name-formatter="formatNames"
                       :disabled="actions.isPreview"
                     />
                   </b-form-group>
                </b-col>
              </b-row>

              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                  :to="{ name: 'apps-transactions-purchase-invoice-list' }"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
                </b-button>

                <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                  {{ $t('globalActions.delete') }}
                </b-button>

                <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-primary"
                  :to="{ name: 'apps-transactions-purchase-invoice-prints', params: { id: paramsId }}"
                >
                  <!-- <feather-icon
                    icon="EyeIcon"
                  /> -->
                  {{ $t('globalActions.prints') }}
                </b-button>

                <!-- <b-button
                  v-if="actions.isPreview && !isInvoiced"
                  class=" mb-sm-1 mb-md-0"
                  variant="success"
                  @click="handleDirectInvoice"
                >
                  <feather-icon
                    icon="CheckIcon"
                  />
                  {{ $t('globalActions.approveToInvoice') }}
                </b-button> -->

                <b-button
                  v-if="actions.isPreview && isInvoiced && posted"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-info"
                  :to="{name: 'apps-transactions-purchase-payment-add-per-invoice', params: { id: paramsId }}"
                >
                  <feather-icon
                    icon="CreditCardIcon"
                  />
                  Receive Payment
                </b-button>

                <b-button
                  v-if="!actions.isPreview"
                  variant="primary"
                  type="submit"
                  @click="handleSubmit"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                  {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.submit') }}
                </b-button>

                <b-button
                  v-else
                  variant="outline-primary"
                  @click="changeToEdit"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  {{ $t('globalActions.changeToEdit') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted, watch } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatCurrency } from '@/utils/formatter'
import { BFormFile } from 'bootstrap-vue'
import useHttp from '@/comp-functions/useHttp'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'

import { format, addDays, parse } from 'date-fns'

const DETAIL_INVOICE = {
  rowNumber: 1,
  itemId: 'Product',
  remark: '',
  uomId: 'Unit',
  quantity: 1,
  amount: 0,
  discPercent: 0,
  discAmount: 0,
  baseAmount: 0,
  discGlobalPercent: 0,
  discGlobalAmount: 0,
  taxBaseAmount: 0,
  taxId: null,
  taxRate: 10,
  taxAmount: 0,
  billAmount: 0
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave,
    BFormFile,
    BSpinner
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/purchase/invoice', localeContextPath: 'apps.transactions.purchase.invoice.singular.invoice', redirectPathName: 'apps-transactions-purchase-invoice' })

    const { $t } = useLocalization()

    const LOV = ref({
      products: [],
      contacts: [],
      uoms: [],
      tags: [],
      taxes: [],
      invoiceTypes:[
        { label: 'With PO', value: 'WITH_PO' },
        { label: 'Without PO', value: 'WITHOUT_PO' }
      ],
      orders: [],
      officeId: []
    })

    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contact/supplier'})
    }

    const getProducts = async () => {
      LOV.value.products = await get({ url: 'value/product?is_buying=true'})
    }

    const getUoms = async () => {
      LOV.value.uoms = await get({ url: 'value/uom'})
    }

    const getTaxes = async () => {
      LOV.value.taxes = await get({ url: 'value/tax'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const getPurchaseOrders = async supplierId => {
      LOV.value.orders = await get({ url: `transaction/purchase/invoice/${supplierId}/orders`})
    }

    const getOfficeId = async () => {
      let companies = storestate.state.user.companies
      let currentCompany = storestate.state.user.currentCompany

      const currentOffice = companies.filter(company => company.company_id == currentCompany.id)

      //condition if user national show all office show office equal user office
      if(storestate.state.user.isNational === true){
        LOV.value.officeId = await get({ url: 'master/offices'})
      }else {
        LOV.value.officeId = await get({ url: 'master/offices'})
        LOV.value.officeId = LOV.value.officeId.filter(company => company.id == currentOffice[0].office_id)
      }
    }

    const tableColumns = ref([])

    const isInvoiced = ref(true)
    const invoiceType = ref('WITHOUT_PO')
    const orders = ref([])

    const form = ref({
      contactId: '',
      code: '',
      date: '',
      invoiceNo: '',
      dueDate: '',
      remark: '',
      officeId: '',
      id: '',
      images: [],
      totalImages: 0,
      tags: [],
      sumOfQuantity: 0,
      sumOfAmount: 0,
      sumOfDiscAmount: 0,
      sumOfBaseAmount: 0,
      sumOfDiscGlobalAmount: 0,
      sumOfTaxBaseAmount: 0,
      sumOfTaxAmount: 0,
      sumOfBillAmount: 0,
      outstandingAmount: 0,
      transactionTypeCode: 'PI',
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        { ...DETAIL_INVOICE }
      ]
    })

    watch(invoiceType, val => {
      if (val === 'WITH_PO') {
        form.value.details = []
        getPurchaseOrders(form.value.contactId)
      }
    })

    const removePOItems = () => {
      // console.log('selectedPO => ', selectedPO)
      // let data = selectedPO.details
      form.value.details = []
    }
    const { toast ,$post } = useHttp()


    // watch(form, (prevVal, val) => {
    //   console.log('prevVal', prevVal)
    //   console.log('val', val)
    //   if (val && invoiceType.value === 'WITH_PO') {
    //     getPurchaseOrders(form.value.contactId)
    //   }
    // })

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
    })

    const sumOfAmount = ref(0)
    const loading    = ref(false)

    const sumItemAmount = () => {
      sumOfAmount.value = form.value.details.reduce((accumulator, {quantity, amount}) => accumulator + (amount * quantity), 0)
      form.value.sumOfQuantity = form.value.details.reduce((accumulator, {quantity}) => accumulator + quantity, 0)
      form.value.sumOfAmount = form.value.details.reduce((accumulator, {amount}) => accumulator + amount, 0)
      form.value.sumOfDiscAmount = form.value.details.reduce((accumulator, {discAmount}) => accumulator + discAmount, 0)
      form.value.sumOfBaseAmount = form.value.details.reduce((accumulator, {baseAmount}) => accumulator + baseAmount, 0)
      form.value.sumOfDiscGlobalAmount = form.value.details.reduce((accumulator, {discGlobalAmount}) => accumulator + discGlobalAmount, 0)
      form.value.sumOfTaxBaseAmount = form.value.details.reduce((accumulator, {taxBaseAmount}) => accumulator + taxBaseAmount, 0)
      form.value.sumOfTaxAmount = form.value.details.reduce((accumulator, {taxAmount}) => accumulator + taxAmount, 0)
      form.value.sumOfBillAmount = form.value.details.reduce((accumulator, {billAmount}) => accumulator + billAmount, 0)
      form.value.outstandingAmount = form.value.sumOfBillAmount
    }

    const updateItemAmount = index => {
      const baseAmountBeforeDisc = form.value.details[index].quantity * form.value.details[index].amount

      if (!form.value.details[index].taxRate) {
        const tax = LOV.value.taxes.find(field => field.id === form.value.details[index].taxId)
        form.value.details[index].taxRate = tax.rate
      }

      form.value.details[index].discAmount = (baseAmountBeforeDisc * form.value.details[index].discPercent) / 100
      form.value.details[index].baseAmount = baseAmountBeforeDisc - form.value.details[index].discAmount
      form.value.details[index].taxBaseAmount = form.value.details[index].baseAmount - form.value.details[index].discGlobalAmount
      form.value.details[index].taxAmount = (form.value.details[index].taxBaseAmount * form.value.details[index].taxRate) / 100
      form.value.details[index].billAmount = form.value.details[index].taxBaseAmount + form.value.details[index].taxAmount
      sumItemAmount()
    }

    const updateItemAmountTax = (index, option, selected) => {
      option.map(field => {
            if(field.id == selected){
                form.value.details[index].taxRate = field.rate
            }
        })

      const baseAmountBeforeDisc = form.value.details[index].quantity * form.value.details[index].amount

      form.value.details[index].discAmount = (baseAmountBeforeDisc * form.value.details[index].discPercent) / 100
      form.value.details[index].baseAmount = baseAmountBeforeDisc - form.value.details[index].discAmount
      form.value.details[index].taxBaseAmount = form.value.details[index].baseAmount - form.value.details[index].discGlobalAmount
      form.value.details[index].taxAmount = form.value.details[index].taxRate  == 0 ? 0 :(form.value.details[index].taxBaseAmount * form.value.details[index].taxRate) / 100
      // console.log(form.value.details[index].taxAmount)
      form.value.details[index].billAmount = form.value.details[index].taxBaseAmount + form.value.details[index].taxAmount
      sumItemAmount()
    }

    // SET AMOUNT AND UNIT BASED ON SELECTED PRODUCT
    const setAmountAndUom = index => {
      // @TODO : SET AMOUNT AND UNIT BASED ON SELECTED PRODUCT
      const { buying_amount, uom: {id} } = LOV.value.products.find(field => field.id === form.value.details[index].itemId)
      form.value.details[index].uomId = id
      form.value.details[index].amount = parseInt(buying_amount)
      updateItemAmount(index)
    }

    const setPOItems = selectedPO => {
      // console.log('selectedPO => ', selectedPO)
      let data = selectedPO.details
      let total_receive = 0
      for (let index = 0; index < selectedPO.detailsGR.length; index++) {
        total_receive = total_receive + parseFloat(selectedPO.detailsGR[index].quantity)
      }

      let i = 0
      data = data.map(field => {
        const customField = {
          rowNumber: 1,
          itemId: field.item_id,
          poId: selectedPO.id,
          remark: field.remark,
          uomId: field.uom_id,
          quantity: parseFloat(total_receive),
          amount: parseFloat(field.amount),
          discPercent: (parseFloat(field.disc_amount) /  parseFloat(field.amount)) * 100,
          discAmount: parseFloat(field.disc_amount),
          baseAmount: parseFloat(field.amount * total_receive),
          discGlobalPercent: parseFloat(field.disc_global_percent),
          discGlobalAmount: parseFloat(field.disc_global_amount),
          taxBaseAmount: ((parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100) == 0 ? 0 : parseFloat(field.amount * total_receive), //jika tidak ada ppn nya maka 0
          taxId: parseFloat(field.tax_id),
          taxRate: (parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100,
          taxAmount: (parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100 == 0 ? 0: ((((parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100)) / 100) * (parseFloat(field.amount * total_receive)),
          billAmount: parseFloat(field.amount * total_receive) + ((parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100 == 0 ? 0: ((((parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100)) / 100) * (parseFloat(field.amount * field.quantity))),
          quantityOrder: parseFloat(selectedPO.detailsGR[i].quantity_order),
          quantityOutstanding: parseFloat(selectedPO.detailsGR[i].quantity_outstanding)
        }
        i++
        return customField
      })
      // console.log(data)
      // console.log('data =>',data)
      form.value.details = data
      sumItemAmount()
    }


    // Add row details
    const addRow = () => {
      const detailInvoice = {...DETAIL_INVOICE}
      detailInvoice.rowNumber = form.value.details.length + 1
      form.value.details.push({
        rowNumber: form.value.details.length + 1,
        ...detailInvoice
      })
    }

    let posted = ref(false)

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      posted.value = data.is_posted
      // console.log(posted.value)
      isInvoiced.value = data.is_invoiced
      form.value.contactId = data.contact_id
      form.value.code = data.code
      form.value.date = data.date
      form.value.officeId = data.office_id
      form.value.dueDate = data.due_date
      form.value.remark = data.remark
      form.value.tags = data.tags.map(tag => parseInt(tag))
      form.value.details = data.details.map(field => {
        const detail = {}
        detail.rowNumber = field.row_number
        detail.itemId = field.item_id
        detail.remark = field.remark
        detail.uomId = field.uom_id
        detail.quantity = parseFloat(field.quantity)
        detail.amount = parseFloat(field.amount)
        detail.discPercent = (parseFloat(field.disc_amount) /  parseFloat(field.amount)) * 100
        detail.discAmount = parseFloat(field.disc_amount)
        detail.baseAmount = parseFloat(field.base_amount)
        detail.discGlobalPercent = parseFloat(field.disc_global_percent)
        detail.discGlobalAmount = parseFloat(field.disc_global_amount)
        detail.taxBaseAmount = parseFloat(field.tax_base_amount)
        detail.taxId = parseFloat(field.tax_id)
        detail.taxRate = (parseFloat(field.tax_amount) /  parseFloat(field.tax_base_amount)) * 100
        detail.taxAmount = parseFloat(field.tax_amount)
        detail.billAmount = parseFloat(field.bill_amount)
        return detail
      })
      sumItemAmount()
    }

    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
    }

    const handleContact = val => {
      if (val.tax_id) {
        form.value.details = form.value.details.map(field => ({...field, taxId: val.tax_id}))
      }
    }

    onMounted(() => {
      getContacts()
      getProducts()
      getUoms()
      getTags()
      getOfficeId()
      getTaxes()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      tableColumns.value = [
        { key: 'product',  label: $t('apps.transactions.purchase.singular.product'), thClass: 'width-30-per', tdClass: 'py-1 align-top pl-0' },
        { key: 'unit', label: $t('apps.transactions.purchase.singular.unit'), thClass: 'text-right width-15-per', tdClass: 'py-1 align-top text-right'},
        { key: 'qty', label: $t('apps.transactions.purchase.singular.qty'), thClass: 'text-right width-10-per', tdClass: 'py-1 align-top text-right'},
        { key: 'price', label: $t('apps.transactions.purchase.singular.price'), thClass: 'text-right width-20-per', tdClass: 'py-1 align-top text-right'},
        { key: 'discount', label: $t('apps.transactions.purchase.singular.discount'), thClass: 'text-right width-10-per', tdClass: 'py-1 align-top text-right'},
        { key: 'tax', label: $t('apps.transactions.purchase.singular.tax'), thClass: 'text-right width-15-per', tdClass: 'py-1 align-top text-right'},
        { key: 'total', label: $t('apps.transactions.purchase.singular.total'), thClass: 'text-right width-25-per', tdClass: 'py-1 align-top text-right'},
        { key: 'actions', thClass: 'text-right width-10-per', tdClass: 'py-1 align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      posted,
      handleContact,
      setPOItems,
      removePOItems,
      invoiceType,
      orders,
      formatCurrency,
      pushTags,
      required,
      LOV,
      tableColumns,
      form,
      addRow,
      removeRow,
      dateFormat,
      sumOfAmount,
      updateItemAmount,
      updateItemAmountTax,
      setAmountAndUom,
      $post,
      toast,
      numeric,
      paramsId,
      isInvoiced,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      BFormFile,
      loading
    }
  },
  methods: {
    formatNames (files) {
      this.form.images = [];
      for (let i = 0; i < files.length; i++) {
          this.form.images.push(files[i]);

      }
      for (let u = 0; u < files.length; u++) {
            const reader = new FileReader()
            // console.log(reader)
            reader.readAsDataURL(this.form.images[u])
      }

        this.form.totalImages = files.length;

     if (files.length === 1) {
        return files[0].name
      }
      return `${ files.length } files selected`
    },
    handleDelete () {
      this.loading = true
      this.destroy(this.$swal)
    },
    async handleSubmit () {
      this.loading = true
      const $swal = this.$swal
      $swal({
        title: `Create new Invoice? After created you still can delete or edit later.`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async () => {
        const forms = new FormData()
        for (let u = 0; u < this.form.images.length; u++) {

            forms.append('avatar'+[u], this.form.images[u])
        }
        forms.append('data',JSON.stringify(this.form))

        const callbackSuccess = () => {

          this.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: `Successfully Created new Invoice!.`
            }
          })
          router.replace({ name: `apps-transactions-purchase-invoice-list` })


        }

        this.$post({
          data:forms,
          url: `/transaction/purchase/invoice`
        }).then((res) => callbackSuccess(res))

      })
    },
    changeInvDate(date){
      const selectedContact = this.LOV.contacts.filter(field => field.id == this.form.contactId)
      const from = parse(date, 'dd/MM/yyyy', new Date())
      const plusDay = addDays(from, parseInt(selectedContact[0].term_of_payment))
      const formatPlusDay = format(plusDay , 'dd/MM/yyyy')
      this.form.dueDate = (selectedContact[0].term_of_payment)?formatPlusDay:date
      // console.log(date)
    }
    // async handleDirectInvoice () {
    //   this.form.isInvoiced = true
    //   this.store({
    //     $swal: this.$swal,
    //     data: this.form
    //   })
    // }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
